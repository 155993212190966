import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import { useDailyLiveDashboard } from "./queryHooks";

interface Metric {
	today: number;
	total: number;
	percentage?: string;
	title: string;
}
const Index = () => {
	const { data, isLoading: loading } = useDailyLiveDashboard();
	const totalRegularOrders = data?.regular_orders_total || 1;

	const liveDashboard: Metric[] = [
		{
			today: data?.unhandled_orders_today || 0,
			total: data?.unhandled_orders_total || 0,
			title: "Unhandled Orders",
		},
		{
			today: data?.undelivered_orders_today || 0,
			total: data?.undelivered_orders_total || 0,
			title: "Undelivered Orders",
		},
		{
			today: data?.customer_hold_orders_today || 0,
			total: data?.customer_hold_orders_total || 0,
			title: "Customer Hold Orders",
		},
		{
			today: data?.regular_orders_today || 0,
			total: data?.regular_orders_total || 0,
			title: "Regular Orders",
		},
		{
			today: data?.trading_orders_today || 0,
			total: data?.trading_orders_total || 0,
			title: "Trading Orders",
		},
		{
			today: data?.facilitation_orders_today || 0,
			total: data?.facilitation_orders_total || 0,
			title: "Facilitation Orders",
		},
		{
			today: data?.return_orders_today || 0,
			total: data?.return_orders_total || 0,
			title: "Return Orders",
			percentage:
				(
					((data?.return_orders_total || 0) / totalRegularOrders) *
					100
				).toFixed(2) + "%",
		},
		{
			today: data?.cbo_orders_today || 0,
			total: data?.cbo_orders_total || 0,
			title: "CBO Orders",
			percentage:
				(
					((data?.cbo_orders_total || 0) / totalRegularOrders) *
					100
				).toFixed(2) + "%",
		},
		{
			today: data?.cancelled_orders_today || 0,
			total: data?.cancelled_orders_total || 0,
			title: "Cancelled Orders",
			percentage:
				(
					((data?.cancelled_orders_total || 0) / totalRegularOrders) *
					100
				).toFixed(2) + "%",
		},
	];

	return (
		<PageScaffold title={pageTitles.LiveDashboard}>
			{loading ? (
				<Flex justifyContent="center" alignItems="center" minH="100vh">
					<Spinner size="xl" color="blue.500" />
				</Flex>
			) : (
				<Flex flexWrap="wrap" gap={6}>
					{liveDashboard.length === 0 ? (
						<Text textAlign="center" w="100%">
							No data available today
						</Text>
					) : (
						liveDashboard.map(
							({ title, today, total, percentage }, index) => (
								<Card
									key={index}
									title={title}
									today={today}
									total={total}
									percentage={percentage}
								/>
							)
						)
					)}
				</Flex>
			)}
		</PageScaffold>
	);
};

export default Index;

const Card = ({ today, total, percentage, title }: Metric) => {
	return (
		<Flex
			w="full"
			maxW="sm"
			height={"60"}
			bg="#333333"
			color="white"
			borderRadius="md"
			p={6}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
			position={"relative"}
		>
			{percentage && (
				<Box
					bg="green.300"
					color="green.900"
					fontSize="xs"
					fontWeight="medium"
					px={3}
					py={0.5}
					borderRadius="full"
					position={"absolute"}
					top={"2"}
					right={"2"}
				>
					{percentage}
				</Box>
			)}
			<Flex justify="space-between" align="start" mb={4} gap={10}>
				<Text fontWeight="bold">
					<Text as="span" fontSize="7xl" color="orange.400">
						{today}
					</Text>
					<Text as="span" fontSize="3xl">
						/
					</Text>
					<Text as="span" fontSize="3xl" color="gray.100">
						{total}
					</Text>
				</Text>
			</Flex>
			<Text fontSize="lg" fontWeight="medium" color={"gray.100"}>
				{title.toUpperCase()}
			</Text>
		</Flex>
	);
};
